import React, { useState, useEffect, useRef } from 'react';
import OnceFooter from "./../common/Footer/OnceFooter";
import Menu from "./../common/Menu/Menu";
import Services from "./../HomeSection/Services";
import SliderHome from "./../HomeSection/SliderHome";
import TopCtaArea from "./../HomeSection/TopCtaArea";
import { useParams } from 'react-router-dom';
import api, { WEB_BASE_URL } from './../../utils/api';
import { AppContext } from "./../context/ContextProvider";
import { Modal, Button, Row, Col, Form, Popover, OverlayTrigger } from 'react-bootstrap';
import { numberFormat, inputKeyPressOnlyNumber } from './../../utils/functions';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import moment from "moment";
import 'moment/locale/th'
import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import bootstrapPlugin from '@fullcalendar/bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const renderEventContent = (eventInfo) => {
  return (
    <>
      <b>ไม่ว่าง</b>
    </>
  )
}

export default function BookingGenerateUrl() {
  const [bookingType, setBookingType] = useState('closeall'); // walkin || car
  const [rooms, setRooms] = useState([]);
  const [room, setRoom] = useState({});
  const [price, setPrice] = useState(0);
  const baseUrl = `${WEB_BASE_URL}/once/self-booking/`
  const [urlText, setUrlText] = useState('');

  const [currentMonth, setCurrentMonth] = useState(new Date())
  const [bookings, setBookings] = useState([])
  const [calendarStartDate, setCalendarStartDate] = useState(null);
  const [calendarEndDate, setCalendarEndDate] = useState(null);
  const calendarRef = useRef(null);

  const [excludeDates, setExcludeDates] = useState([])

  const [mainCurrentStartDate, setMainCurrentStartDate] = useState(new Date());
  const [mainCalendarStartDate, setMainCalendarStartDate] = useState(null);
  const [mainCalendarEndDate, setMainCalendarEndDate] = useState(null);
  const [blockdays, setBlockdays] = useState([]);

  const [showBookingsInfo, setShowBookingsInfo] = useState(false);
  const [bookingsForShow, setBookingsForShow] = useState([]);

  const onPrevButtonClick = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    setCurrentMonth(calendarApi.currentData?.currentDate || new Date())
  };

  const onNextButtonClick = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    setCurrentMonth(calendarApi.currentData?.currentDate || new Date())
  };

  const getToday = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().today();
      setCurrentMonth(new Date())
    }
  };

  const handleDates = (rangeInfo) => {
    setCalendarStartDate(rangeInfo?.start)
    setCalendarEndDate(rangeInfo?.end)
  };

  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const a = moment(new Date(moment(endDate).format('YYYY-MM-DD')));
  const b = moment(new Date(moment(startDate).format('YYYY-MM-DD')));
  const dayQty = a.diff(b, 'days') | 0

  // let newEvent = []
  // if (startDate && endDate && a && b) {
  //   newEvent = [{
  //     start: moment(startDate).format('YYYY-MM-DD'),
  //     end: moment(endDate).add(1, 'days').format('YYYY-MM-DD'),
  //     color: '#57BD37',
  //   }]
  // }

  const fetchRooms = () => {
    api.request('rooms/all', (data) => {
      if (data && data.length) {
        setRooms(data)
      } else {
        setRooms([])
      }
    }, {
      hotel_id: '61cad24f-bc1e-4cb2-8aec-15c62557bd1b',
      room_type_id: '248777bc-2bc9-4d85-8c7c-4ef3fa94014f',
      enabled: true
    })
  }

  const fetchฺBooking = (room) => {
    api.request('bookings/all-check-room', (data) => {
      if (data && data.length) {

        let ex = []

        data.map((item) => {
          const num = Number(item.day_qty)
          if (num > 1) {
            for (let i = 0; i < num; i++) {
              if (i === 0) {
                ex.push(new Date(item.start_date))
              } else {
                const nDate = new Date(item.start_date)
                ex.push(nDate.setDate(nDate.getDate() + i))
              }
            }
          } else {
            ex.push(new Date(item.start_date))
          }
        })

        setExcludeDates(ex)
      } else {
        setExcludeDates([])
      }
    }, {
      hotel_id: room.hotel_id,
      room_type_id: room.room_type_id,
      room_id: room.id,
      start_date: calendarStartDate,
      end_date: calendarEndDate
    })
  }

  const fetchBookingData = (rId) => {
    api.request('bookings/all-check-room', (data) => {
      if (data && data.length) {
        setStartDate(null)
        setEndDate(null)
        setPrice(0)
        alert('ห้องพักไม่ว่างในวันที่เลือก! กรุณาเลือกวันอื่นๆ')
        return
      } else {
        setPrice((room?.total_price || 0) * dayQty)
      }
    }, {
      room_id: rId,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).subtract(1, 'days').format('YYYY-MM-DD')
    })
  }

  const checkCanCloseAll = (rId) => {
    api.request('bookings/bookingall/checkcanbook', (data, s) => {
      if (!s || !data) {
        alert('!!! ไม่สามารถปิดลานในวันนี้ได้ !!!')
        return
      }
      if (data && data.can_booking) {
        alert('สามารถปิดลานได้')
        return
      } else {
        alert('!!! ไม่สามารถปิดลานในวันนี้ได้ !!!')
        return
      }
    }, {
      hotel_id: '61cad24f-bc1e-4cb2-8aec-15c62557bd1b',
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).subtract(1, 'days').format('YYYY-MM-DD')
    }, 'PUT')
  }

  useEffect(() => {
    if (room?.id && bookingType === 'car') {
      fetchฺBooking(room)
    }
  }, [room?.id])

  useEffect(() => {
    if (!!startDate && !!endDate && !!room?.id && bookingType === 'car') {
      fetchBookingData(room.id)
    }
  }, [startDate, endDate, room])

  useEffect(() => {
    fetchฺBookingFromDate(mainCurrentStartDate)
    fetchฺBlockdayFromDate(mainCurrentStartDate)
    fetchRooms()
  }, [])

  useEffect(() => {
    if (!!mainCurrentStartDate) {
      fetchฺBookingFromDate(mainCurrentStartDate)
      fetchฺBlockdayFromDate(mainCurrentStartDate)
    }
  }, [mainCurrentStartDate])

  useEffect(() => {
    setUrlText('')
  }, [bookingType, room, startDate, endDate, price])

  const fetchฺBookingFromDate = (currentDate) => {
    if (!currentDate) {
      setBookings([])
      return
    }

    api.request('bookings/all-check-room', (data) => {
      setBookings(data || [])
    }, {
      hotel_id: '61cad24f-bc1e-4cb2-8aec-15c62557bd1b',
      start_date: moment(currentDate).startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
      end_date: moment(currentDate).endOf('month').add(7, 'days').format('YYYY-MM-DD')
    })
  }

  const fetchฺBlockdayFromDate = (currentDate) => {
    if (!currentDate) {
      setBlockdays([])
      // setRoomIdsInBookings([])
      return
    }

    api.request('bookings/all-check-room-blockday', (data) => {
      if (data && data.length) {
        setBlockdays(data.map((item) => { return item.date }) || [])
      } else {
        setBlockdays([])
      }
    }, {
      hotel_id: '61cad24f-bc1e-4cb2-8aec-15c62557bd1b',
      start_date: moment(currentDate).startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
      end_date: moment(currentDate).endOf('month').add(7, 'days').format('YYYY-MM-DD')
    })
  }

  const checkCloseAll = (date) => {
    const hasClose = bookings.filter((item) => {
      return item.is_close_zone && item.close_zone_type === 'all' && date >= item.start_date && date <= item.end_date
    })

    return hasClose.length > 0
  }

  const checkBooking = (date) => {
    const hasBooking = bookings.filter((item) => {
      return !item.is_close_zone && date >= item.start_date && date <= item.end_date
    }) || []

    const carBooking = hasBooking.filter((item) => {
      return !!item.room_id && !item.is_walkin
    })

    const walkinBooking = hasBooking.filter((item) => { return item.is_walkin })

    return {
      all: hasBooking.length || 0,
      car: carBooking.length || 0,
      carNames: carBooking.length > 0 ? ((carBooking.map((b) => { return b.room?.name_th }) || []).join(', ')) : '',
      walkin: walkinBooking.length || 0,
    }
  }

  const getBookingsInfo = (date) => {
    const hasBookings = bookings.filter((item) => {
      return !item.is_close_zone && date >= item.start_date && date <= item.end_date
    }) || []

    return hasBookings.sort((a, b) => {
      return a.is_walkin ? 1 : -1
    })
  }

  function tileClassName({ date, view }) {
    if (view !== 'month') return null;
    const classes = ['booking-calendar-date', 'px-1'];
    const isPartDate = moment(date).isBefore(moment(), 'day')
    const dateStr = moment(date).format('YYYY-MM-DD')

    if (!isPartDate) {
      if (moment(date).isoWeekday() === 5 || moment(date).isoWeekday() === 6) {
        classes.push('custom-weekend');
      }

      // if (checkHoliday(date)) {
      //   classes.push('custom-holiday');
      // }

      if (checkCloseAll(dateStr)) {
        classes.push('custom-close-all');
      }

      const checkBookingData = checkBooking(dateStr)
      if (checkBookingData.all > 0) {
        classes.push('custom-has-booking');
      }

      if (blockdays.includes(dateStr)) {
        classes.push('custom-close-room');
      }

      // if (checkCustomPrice(date)) {
      //   classes.push('custom-price');
      // }
    } else {
      classes.push('part-date');
    }

    return classes.join(' ');
  }

  return (
    <>
      {/* <Menu header_style_2={true} /> */}
      <div className="text-center">
        <div className="px-5 py-3">
          <div className="d-flex">
            <label className="form-check">
              <input type="radio" className="form-check-input" checked={bookingType === 'closeall'} onChange={(e) => setBookingType('closeall')} name="bookingTypeInput" />
              <span>เหมาลาน</span>
            </label>
            <label className="form-check ms-3">
              <input type="radio" className="form-check-input" checked={bookingType === 'walkin'} onChange={(e) => setBookingType('walkin')} name="bookingTypeInput" />
              <span>กางเต็นท์</span>
            </label>
            <label className="form-check ms-3">
              <input type="radio" className="form-check-input" checked={bookingType === 'car'} onChange={(e) => setBookingType('car')} name="bookingTypeInput" />
              <span>เช่ารถบ้าน</span>
            </label>
          </div>
          {bookingType === 'car' && rooms.length > 0 && <div className="text-start mt-2">
            <p className="text-black">เลือกรถบ้าน</p>
            <div>
              {rooms.map((r, i) => {
                return (
                  <label className="form-check ms-2" key={`select-room-${r.id}`}>
                    <input type="radio" className="form-check-input" checked={room?.id === r.id} onChange={(e) => {
                      setRoom(r)
                    }} name="carId" />
                    <span>{r.name_th} : {r.suggestion_guest_info_th}</span>
                  </label>
                )
              })}
            </div>
          </div>}
          {/* {bookingType === 'closeall' && <div className="text-start mt-2">
            <p className="text-danger">เลือกวันที่เพื่อเช็คสถานะ</p>
          </div>
          } */}
          <div className="booking-form-input">
            <div className="mt-2">
              {bookingType !== 'closeall' && <DatePicker
                className="form-control search-booking-datepicker"
                minDate={new Date()}
                swapRange
                selected={startDate}
                onChange={(dates) => {
                  const [start, end] = dates;
                  setStartDate(start);
                  setEndDate(end);
                }}
                excludeDates={excludeDates}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
                dateFormat="dd/MM/yyyy"
              />}
              {bookingType === 'closeall' &&
                <div className={`mt-2 mb-4`} style={{ maxWidth: 500 }}>
                  <div className="mb-2 d-flex title-btn">
                    <span onClick={() => setMainCurrentStartDate(new Date())} className="text-black border rounded py-1 px-2"><i className="fal fa-calendar"></i> TODAY</span>
                  </div>
                  <Calendar
                    showDoubleView={false}
                    minDate={new Date()}
                    calendarType={'hebrew'}
                    locale={'th-TH'}
                    activeStartDate={mainCurrentStartDate}
                    showNavigation={true}
                    tileClassName={tileClassName}
                    tileContent={({ activeStartDate, date, view }) => {
                      let priceStr = ''
                      const dateStr = moment(date).format('YYYY-MM-DD')

                      if (blockdays.includes(dateStr)) {
                        priceStr = 'Block Day'
                      }

                      if (checkCloseAll(dateStr)) {
                        priceStr = 'เหมาลาน'
                      }

                      const checkBookingData = checkBooking(dateStr)

                      if (checkBookingData.all > 0) {
                        priceStr = checkBookingData.all + ' การจอง'
                      }

                      return (view === 'month') ?
                        (
                          <div className="price-container">
                            {checkBookingData.all === 0 && <p className={`booking-calendar-price`}>{priceStr}</p>}
                            {checkBookingData.all > 0 && <p className={`booking-calendar-price`}>
                              {checkBookingData.car > 0 && <span><i className="fal fa-caravan"></i> {checkBookingData.car}</span>}
                              {(checkBookingData.car > 0 && checkBookingData.walkin > 0) && <span className="ps-1"></span>}
                              {checkBookingData.walkin > 0 && <span><i className="fal fa-campground"></i> {checkBookingData.walkin}</span>}
                            </p>
                            }
                          </div>
                        )
                        : null
                    }}
                    value={null}
                    onActiveStartDateChange={(s) => {
                      if (s?.activeStartDate) {
                        setMainCurrentStartDate(s.activeStartDate)
                      }
                    }}
                    onClickDay={(date) => {
                      const dateStr = moment(date).format('YYYY-MM-DD')
                      if (checkCloseAll(dateStr)) {
                        return
                      }

                      const checkBookingData = checkBooking(dateStr)
                      if (checkBookingData.all > 0) {
                        setBookingsForShow(getBookingsInfo(dateStr))
                        setShowBookingsInfo(true)
                      }
                    }}
                  />
                </div>
              }
            </div>
            {/* <div className="pe-5 ps-2 mt-2">
              <div className="text-start">
                <div>จำนวนวันที่เข้าพัก</div>
                {startDate && endDate && <strong className="text-black">{moment(startDate).format('DD/MM/YYYY')} - {moment(endDate).format('DD/MM/YYYY')} ({dayQty}) คืน</strong>}
                {(!startDate || !endDate) && <div className="text-warning">กรุณาเลือกวันเข้าพัก</div>}
              </div>
            </div> */}
            {/* {bookingType === 'closeall' && <div>
              <Button disabled={(!startDate || !endDate)} className="btn btn-sm btn-warning mt-4" onClick={() => {
                checkCanCloseAll()
              }
              }>เช็คสถานะก่อนปิดลาน</Button>
            </div>} */}
            {bookingType !== 'closeall' && <div>
              <div className="pe-5 ps-2">
                <div className="text-start">
                  <div>ราคา</div>
                  <input
                    type="number"
                    name="price"
                    placeholder="ระบุราคา"
                    onKeyPress={inputKeyPressOnlyNumber} value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    required
                  />
                </div>
              </div>
              <Button className="btn btn-sm btn-success mt-4" onClick={() => {
                if (dayQty < 1) {
                  alert('กรุณาเลือกวันให้ถูกต้อง !')
                  return
                }

                if (bookingType === 'car' && !room?.id) {
                  alert('อย่าลืมเลือกรถก่อนค่ะ !')
                  return
                }
                const url = `${baseUrl}${bookingType}/${bookingType === 'car' ? room.id : 'abc'}/${price || 0}/${moment(startDate).format('YYYYMMDD')}/${moment(endDate).format('YYYYMMDD')}`
                setUrlText(url)
              }}>Generate Link</Button>
              {!!urlText && urlText.length > 0 && <div className="text-start mt-3 border p-2">
                <div> <small className="text-start text-black">{urlText}</small></div> <Button className="btn btn-sm btn-warning" onClick={() => {
                  navigator.clipboard.writeText(urlText)
                }}>Copy Link</Button>
              </div>}
            </div>}
          </div>
        </div>
      </div>
      <Modal show={showBookingsInfo} onHide={() => {
        setShowBookingsInfo(false)
        setBookingsForShow([])
      }} backdrop="static">
        <Modal.Header closeButton className="p-3">
          <Modal.Title>รายละเอียดการจองห้องพัก</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-1 booking-status">
          <div className="text-start p-0">
            <table className="table table-striped">
              <tbody>
                {bookingsForShow.map((b, i) => {
                  const icon = b.is_walkin ? 'fal fa-campground' : 'fal fa-caravan'
                  const walkinPerson = b.is_walkin ? Number(b.guest_qty || 0) + Number(b.guest_child_qty || 0) : 0

                  return (
                    <tr key={`booking-info-${i}`}>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className={icon}></i>
                          <div className="ms-2">
                            {!b.is_walkin && <div>{b.room?.name_th}</div>}
                            {!!b.is_walkin && <div>{numberFormat(walkinPerson, true)} ท่าน</div>}
                          </div>
                        </div>
                      </td>
                      <td>{b.contact_name}</td>
                      <td>{b.contact_tel_1}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
      <OnceFooter />
    </>
  );
}
